<template>
	<div class="signup-form-component" :class="$responsive">
		<p class="subtitle">
			Por favor completa la siguiente información para el alta y configuración inicial de tu servicio.
		</p>
		<form class="signup-form" method="post" action="#" @submit.prevent="submitForm">
			<section class="signup-section" v-for="(section, sectionIndex) in form" :key="sectionIndex">
				<h3 class="signup-section__title" v-if="section.title">
					{{ section.title }}
				</h3>
				<div class="signup-section__optional" v-if="section.optional">
					<input type="checkbox" v-model="section.optional.value">
					<span>{{ section.optional.description }}</span>
				</div>
				<p class="signup-section__description" v-if="section.description">
					{{ section.description }}
				</p>
				<div v-show="showSection(section)">
					<div v-if="section.fields">
						<div v-for="(field, key, index) in section.fields" :key="key">
							<form-field :index="index" :field="field" v-model.trim="field.value" />
						</div>
					</div>
					<div v-else-if="section.list">
						<input-list :list="section.list" v-model="section.list.data"></input-list>
					</div>
				</div>
				<div class="signup-comments" v-if="section.comment">
					{{ section.comment }}
				</div>
				<div class="signup-section__error-message" v-if="section.errorMessage">
					{{ section.errorMessage[0] }}
				</div>
			</section>
			<div class="disclaimer">
				<input type="checkbox" v-model="disclaimer.accepted" @change="checkDisclaimer()">
				<span class="disclaimer__error" v-show="disclaimer.error">**</span>
				{{ disclaimer.description }}
			</div>
			<base-button class="signup-form__submit">
				Enviar Información
			</base-button>
			<div class="signup__error-msg" v-show="!errorFree">
				Revisa la información y completa los campos necesarios para la configuración del servicio.
			</div>
			<re-captcha ref="recaptcha" @verify="doSubmit"></re-captcha>
		</form>
	</div>
	<modal-processing v-if="isSending">
		Enviando tu información..
	</modal-processing>
</template>

<script>
const axios = require('axios');
import reCaptcha from "@/components/ReCaptchaV2.vue";
import modalProcessing from "@/components/ModalProcessing.vue";
import baseButton from "@/components/BaseButton.vue";
import formField from "@/components/FormField";
import useForm from "@/forms.js";
import inputList from "@/components/InputList.vue";

export default {
	name: "SettingsForm",
	components: {
		reCaptcha,
		modalProcessing,
		baseButton,
		formField,
		inputList
	},
	emits: ['sent'], // new on Vue3
	setup() {
		const { checkSections } = useForm();
		return { checkSections };
	},
	data() {
		return {
			form: [
				{
					title: "Datos de la liga",
					description: "Escribe los datos de la liga.",
					fields: {
						leagueName: {
							value: "",
							type: "text",
							label: "Nombre de la Liga",
							required: true,
							errorMessage: []
						},
						dts: {
							value: "",
							type: "text",
							label: "Referencia DTS",
							required: true,
							errorMessage: [],
							helpText: "Es la referencia con la que identificamos tu servicio"
						},
						contact: {
							value: "",
							type: "text",
							label: "Nombre y Apellido de Contacto",
							required: true,
							errorMessage: [],
							helpText: "Escribe tu Nombre y Apellido"
						}
					}	
				},
				{
					comment: 'A continuación describe las preferencias para configurar tu servicio. Deja las opciones que utilizas, añade nuevos conceptos y borra los que no apliquen para tu Liga.'
				},
				{
					title: "Conceptos de Cargo / Torneos",
					description: "Registra los conceptos de los cargos que realizas a los equipos en los torneos.",
					list: {
						name: "Cargos Torneos",
						model: { accountName: '' },
						labels: ["Descripción"],
						addLabel: "+ añadir concepto",
						data: [
							{ accountName: 'Inscripción' },
							{ accountName: 'Arbitraje' },
							{ accountName: 'Cuota Torneo' },
							{ accountName: 'Credenciales' },
							{ accountName: 'Partido Amistoso' }
						]
					}
				},
				{
					title: "Formas de Pago",
					description: "Registra las diferentes formas de pago que utilizas.",
					list: {
						name: "Formas de Pago",
						model: { paymentName: '' },
						labels: ["Descripción"],
						addLabel: "+ añadir forma de pago",
						data: [
							{ paymentName: 'Efectivo' },
							{ paymentName: 'Cheque' },
							{ paymentName: 'Transferencia' },
							{ paymentName: 'Tarjeta' }
						]
					}
				},
				{
					optional: {
						description: "Añadir Rentas",
						value: false
					},
					description: "Registra los conceptos y el precio de lista de Rentas que manejas en la Liga.",
					list: {
						name: "Rentas",
						model: { assetName: '', price: '' },
						labels: ["Descripción", "Precio de lista"],
						addLabel: "+ añadir concepto renta",
						data: [
							{ assetName: 'Cancha 1', price: '0.00' },
							{ assetName: 'Cancha 2', price: '0.00' },
							{ assetName: 'Casacas', price: '0.00' },
							{ assetName: 'Balón', price: '0.00' },
							{ assetName: 'Palapa', price: '0.00' },
							{ assetName: 'Asador', price: '0.00' }
						]
					}
				},
				{
					title: "Recibos y comprobantes de pago",
					description: "Describe la manera en la que deseas manejar los comprobantes de pago.",
					fields: {
						paymentReceipt: {
							value: "Impreso por el sistema",
							type: "select",
							label: "Tipo de comprobante",
							values: ['Impreso por el sistema', 'Talonario físico'],
							required: false,
							errorMessage: []
						},
						printerType: {
							value: "Cualquiera",
							type: "select",
							label: "Tipo de impresión",
							values: ['Cualquiera', 'Impresora (tinta, laser)', 'Impresora Rollo (papel térmico)'],
							required: false,
							errorMessage: [],
							helpText: "Aplica solo con recibos generados por el sistema."
						},
					}
				},
				{
					optional: {
						description: "Gestión de Egresos",
						value: false
					},
					description: "Registra los centros de costo para la gestión de los egresos de la Liga.",
					list: {
						name: "Centros de Costo",
						model: { costCenter: '' },
						labels: ["Descripción"],
						addLabel: "+ añadir centro de costo",
						data: [
							{ costCenter: 'Torneos' },
							{ costCenter: 'Instalaciones' },
							{ costCenter: 'Operaciones' }
						]
					}
				}
			],
			sent: false,
			isSending: false,
			errorFree: true,
			disclaimer: {
				accepted: false,
				error: false,
				description: "Estoy de acuerdo y autorizo la activación de zione.sport Saldos así como el incremento en el cargo de la facturación basado en las condiciones señaladas en la cotización correspondiente por este servicio."
			}
		}
	},
	methods: {
		showSection(section) {
			return !section.optional || section.optional.value;
		},
		doSubmit(token) {
			const getFields = fields => Object.entries(fields).reduce((acc, el) => ({...acc, [el[0]]: el[1].value }), {});
			const leagueData = this.form.reduce((obj, section) => ({...obj, ...getFields(section.fields || {})}), {});

			const settingsData = this.form.filter(section => section.list)
				.map(section => ({ name: section.list.name, data: section.list.data }));

			this.isSending = true;
			axios.post('/api/settings', {
					data: leagueData,
					settings: settingsData,
					disclaimer: this.disclaimer,
					recaptchaToken: token
				})
				.then(response => {
					console.log(response);
					this.sent = true;
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.isSending = false;
					this.$emit('sent', this.leagueName);
				});
		},
		submitForm() {
			let checkSections = this.checkSections(this.form);
			let checkDisclaimer = this.checkDisclaimer();
			this.errorFree = checkSections;
			if (this.errorFree && checkDisclaimer) { 
				this.$refs.recaptcha.execute();
			}
		},
		checkDisclaimer() {
			this.disclaimer.error = !this.disclaimer.accepted;
			return this.disclaimer.accepted;
		}
	}
}

</script>

<style scoped>
.signup-form-component {
	margin: 2rem auto 0;
	background-color: #ffffff;
	padding: 1rem 3rem;
	border-radius: 1rem;
}

.signup-form-component.mobile {
	padding: 1rem;
}

.signup-form-component.desktop {
	max-width: 80%;	
}

.desktop .subtitle {
	margin: 2rem auto;
	max-width: 80%;
}

.signup-form {
	font-family: 'open sans', helvetica, sans-serif;
}

.signup-form__submit {
	margin: 2.5rem auto 2rem;	
}

.signup-section {
	margin-bottom: 3.75rem;
}

.mobile .signup-section {
	margin-bottom: 4.75rem;	
}

.signup-section__title {
	font-size: .9375rem;
	text-align: left;
	margin: 0 0 .75rem;
}

.signup-section__description {
	font-size: .8125rem;
	text-align: left;
	color: var(--secondary-color);
	line-height: 1.66em;
	margin: 0 0 1.75rem;
}

.signup-section__optional {
	text-align: left;
	font-size: .8125rem;
	margin-bottom: .75rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.signup-section__optional > span {
	margin-left: .5em;
}

.signup-comments {
	text-align: left;
	font-size: .9375rem;
	font-size: .875rem;
	border-bottom: 1px solid #efefef;
	padding-bottom: 1.5rem;
	margin-top: 2rem;
	line-height: 1.66em;
}

.signup__error-msg {
	color: var(--error-color);
	margin-bottom: 2rem;
}

.signup-section__error-message {
	color: var(--error-color);
	text-align: left;
	font-size: .875rem;
	margin-top: 1em;
}

.disclaimer {
  text-align: left;
  font-size: .8125rem;
  margin-bottom: 2rem;
}

.disclaimer__error {
	color: var(--error-color);
}
</style>