<template>
	<section class="input-list">
		<ul class="input-list__data">
			<li class="input-list__item" v-for="(item, index) in list.data" :key="index">
				<div class="form-field" v-for="(value, key, _index) in list.model" :key="key">
					<input type="text" v-model="item[key]" :placeholder="list.labels[_index]" :ref="`field-${index}-${_index}`">	
				</div>
				<span class="input-list__remove" @click="removeItem(index)" title="remover">
					<fa-icon :icon="['fas', 'times']"/>
				</span>
			</li>
			<li class="list-add">
				<button class="list-add__button" @click.prevent="addItem">
					{{ list.addLabel }}
				</button>
			</li>
		</ul>
	</section>
</template>

<script>
	export default {
		name: "InputList",
		emits: ['update:modelValue'],
		props: {
			list: Object,
			modelValue: Array
		},
		methods: {
			addItem() {
				let arr = [...this.modelValue, { ...this.list.model }];
				this.$emit("update:modelValue", arr);
				this.$nextTick(() => this.$refs[`field-${arr.length - 1}-0`].focus());
			},
			removeItem(index) {
				let arr = this.modelValue.filter((item, idx) => idx !== index);
				this.$emit("update:modelValue", arr);
			}
		}
	}
</script>

<style scoped>
.input-list__item {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.input-list .form-field {
	flex: 1 1 auto;
	text-align: left;
	margin-right: 2rem;
}

.mobile .input-list .form-field {
	margin-right: 1rem;
}

.input-list__remove {
	font-size: .875em;
	cursor: pointer;
	color: var(--secondary-color);
	opacity: .75;
}

.input-list__remove:hover {
	opacity: 1
}

.list-add {
	text-align: left;
	padding-top: .25rem;
}

.mobile .list-add {
	padding-top: .66rem;
}

.list-add__button {
  font-size: .75rem;
  font-weight: 400;
  background-color: #f6f7f8;
  padding: .25em 1em;
  color: var(--secondary-color);
  border: none;
  border-radius: 1em;
  outline-width: 0;
}

.mobile .list-add__button {
	font-size: 1.125rem;
	width: 100%;
}

.list-add__button:hover {
	color: var(--primary-color);
}

</style>