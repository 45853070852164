<template>
	<div class="success-message">
		<div class="success-message__content">
			<fa-icon :icon="['fas', 'paper-plane']" size="4x" class="sm__icon" />
			<h2 class="sm__title">¡Gracias!</h2>
			<p class="subtitle sm__subtitle">
				<span v-if="!!contactName" class="sm__contact-name">
					{{ contactName }},
				</span>
				<slot></slot>
			</p>	
		</div>
		<div class="success-message__marketing" v-if="!!marketing">
			<marketing-box :image="marketing.image" :title="marketing.title" :link="marketing.link">
				{{ marketing.description }}
			</marketing-box>
		</div>
	</div>
</template>

<script>
	import marketingBox from "@/components/MarketingBox.vue";

	export default {
		name: "SuccessMessage",
		components: {
			marketingBox
		},
		props: {
			contactName: String,
			marketing: Object
		},
	}
</script>

<style scoped>

.success-message {
	background-color: #ffffff;
	padding: 3rem 2rem;
	border-radius: 1rem;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;
	justify-items: center;
}

.success-message__marketing {
	margin-top: 6rem;
	width: 100%;
	max-width: 500px;
}

.success-message__content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sm__title {
	font-size: 2.25rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
	margin: 1em 0 .33rem;
}

.sm__icon {
	color: var(--primary-color);
}

.sm__contact-name {
	font-weight: 600;
	font-size: clamp(1.33rem, 5vw, 1.66rem);
	display: block;
	margin-bottom: .125em;
	color: var(--primary-color);
	letter-spacing: -0.066rem;
}
</style>