<template>
	<div class="settings">
		<div class="view-header">
			<h3 class="view-header__legend">Configuración de Servicio</h3>
			<h1 class="title" v-markdown:title>
				zione.sport *Saldos*
			</h1>
		</div>
		<div class="page-section" :class="$responsive">
			<div v-if="!sent">
				<settings-form @sent="sentDone"></settings-form>
			</div>
			<div v-else>
				<success-message contactName="Genial">
					hemos recibido los datos para configurar tu servicio de zione.sport Saldos.
				</success-message>
			</div>
		</div>
	</div>
</template>

<script>
	import settingsForm from "@/components/SettingsForm.vue";
	import successMessage from "@/components/SuccessMessage.vue";
	
	export default {
		name: "Signup",
		components: {
			settingsForm,
			successMessage
		},
		data() {
			return {
				sent: false,
				leagueName: null
			}
		},
		methods: {
			sentDone(leagueName) {
				this.leagueName = leagueName;
				this.sent = true;
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}
		}
	}
</script>

<style scoped>
.settings .view-header {
	min-height: 0;
	padding-top: 0;
}
.page-section.mobile {
	padding: 1rem;
}
</style>