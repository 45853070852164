<template>
	<div class="marketing-box">
		<h2 class="title" v-if="!!title" v-markdown:title>
			{{ title }}
		</h2>
		<div class="marketing-box__image">
			<img :src="require(`@/assets/${image}`)">	
		</div>
		<p class="marketing-box__text subtitle" v-markdown:text>
			<slot></slot>
		</p>
		<div v-if="link">
			<base-link :link="link"/>
		</div>
	</div>
</template>

<script>
	import baseLink from "@/components/BaseLink.vue";
	
	export default {
		name: "MarketingBox",
		props: {
			image: { type: String },
			title: { type: String },
			link: {type: Object}
		},
		components: {
			baseLink
		}
	}
</script>

<style scoped>

.marketing-box {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	width: 100%;
	margin: 0 auto;
	gap: 1rem;
}

.marketing-box .title {
	font-size: clamp(1.125rem, 5vw, 1.5rem);
	margin: 0 auto;
	max-width: 80%;
	line-height: 1.25;
}

.marketing-box__image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.marketing-box__image > img {
	width: 100%;
	
}

.marketing-box__text {
	margin: 0;
}

</style>